<template>
  <div class="cyberinsurancepay" id="cyberinsurance-pay">
    <CyberInsurancePackage :class="{ packageDiv: callBackApi.formPackage }" />
  </div>
</template>

<style lang="scss" scoped>
.packageDiv {
  display: none !important;
}
</style>
<script>
import CyberInsurancePackage from "../components/MicroSite/CyberInsurancePackage.vue";
export default {
  data() {
    return {
      callBackApi: [
        {
          formPackage: false,
        },
      ],
    };
  },
  methods: {},
  name: "cyberinsurancepay",
  components: {
    CyberInsurancePackage,
  },

  mounted() {
    const element = document.getElementById("cyberinsurance-pay");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  },
};
</script>
