<template>
  <div class="div-padding bg-content-white">
    <b-container class="pt-5 pb-5 wrapper">
      <b-row class="d-flex mt-5-custome justify-content-center">
        <form
          accept-charset="UTF-8"
          v-on:submit.prevent="onSubmit()"
          method="POST"
          enctype="multipart/form-data"
          id="ajaxForm"
          v-if="isValidData"
        >
          <div id="infoPackageDiv" class="form-box-white">
            <b-row class="loading" :class="{ loadingDiv: loading }"></b-row>
            <!-- <pre>{{ receivedData }}</pre> -->
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col class="pt-2">
                <span class="text-1">
                  {{ $t("website.insurance.forminsurancepay.title") }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2 mt-2 d-flex justify-content-between">
              <b-col class="pt-2">
                <span class="text-3">
                  {{ $t("website.insurance.forminsurancepay.options") }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col class="pt-2">
                <label
                  v-for="option in options"
                  :key="option.value"
                  class="
                    d-flex
                    radio-container
                    justify-content-between
                    radio-div
                    col-12
                  "
                  :class="{
                    divPackage: selectPackage === option.value,
                  }"
                >
                  <div>
                    <span class="text-2">{{ option.text }}</span>
                    &nbsp;
                    <span class="text-4">{{ option.desc }}</span>
                  </div>

                  <input
                    type="radio"
                    :value="option.value"
                    v-model="selectPackage"
                    style="position: fixed; opacity: 0; pointer-events: none"
                  />
                  <span class="checkmark"></span>
                </label>
              </b-col>
            </b-row>
            <b-row class="mt-2 d-flex justify-content-between">
              <b-col class="pt-2">
                <span class="text-3">
                  {{ $t("website.insurance.forminsurancepay.item") }}
                </span>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col class="pt-2 col-7">
                <span class="text-5">
                  {{ $t("website.insurance.forminsurancepay.desc1") }}
                  {{ clientName }}
                </span>
              </b-col>
              <b-col class="pt-2 col-4 d-flex flex-row-reverse">
                <span class="text-5"> RM {{ annualPay(selectPackage) }} </span>
              </b-col>
            </b-row>
            <hr />
            <b-row class="d-flex justify-content-between">
              <b-col class="col-7">
                <span class="text-5"> Total excluding tax </span>
              </b-col>
              <b-col class="col-4 d-flex flex-row-reverse">
                <span class="text-5"> RM {{ totalExTax(selectPackage) }} </span>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-between">
              <b-col class="col-7">
                <span class="text-5"> SST 6% </span>
              </b-col>
              <b-col class="col-4 d-flex flex-row-reverse">
                <span class="text-5"> RM {{ taxPay(selectPackage) }} </span>
              </b-col>
            </b-row>
            <hr />
            <b-row class="mt-2 d-flex justify-content-between">
              <b-col class="pt-2">
                <span class="text-5">
                  {{ $t("website.insurance.forminsurancepay.rounding") }}
                </span>
              </b-col>
              <b-col class="col-4 d-flex flex-row-reverse">
                <span class="text-5">
                  RM {{ totalRoundUp(selectPackage) }}
                </span>
              </b-col>
            </b-row>

            <b-row class="mt-2 d-flex justify-content-between">
              <b-col class="pt-2">
                <span class="text-3">
                  {{ $t("website.insurance.forminsurancepay.total") }}
                </span>
              </b-col>
              <b-col class="col-4 d-flex flex-row-reverse">
                <span class="text-5"> RM {{ annualPay(selectPackage) }} </span>
              </b-col>
            </b-row>
            <b-row class="mb-2 d-flex justify-content-center">
              <b-button
                type="submit"
                :disabled="isDisabled"
                class="button-submit"
                id="submitbtn"
                >{{ $t("website.insurance.forminsurancepay.checkout") }}
              </b-button>
            </b-row>

            <b-row class="mb-3">
              <b-col sm="4" class="pt-2"></b-col>
              <b-col sm="8">
                <b-modal v-model="modalSuccessbox">
                  <b-icon icon="check-circle-fill" variant="success"></b-icon
                  >&nbsp; {{ $t("website.contactus.text1") }}
                </b-modal>
                <b-modal v-model="modalFailbox">
                  <b-icon icon="x-circle-fill" variant="danger"></b-icon>&nbsp;
                  {{ $t("website.contactus.text2") }}</b-modal
                >
                <b-modal v-model="modalAgreebox"
                  >{{ $t("website.contactus.text3") }}.</b-modal
                >
              </b-col>
            </b-row>
          </div>
        </form>
        <CyberInsuranceLoader v-else-if="!isValidData" />
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-row class="mt-2 d-flex justify-content-center text-center">
          <b-col class="pt-2 col-7">
            <span class="text-6">
              {{ $t("website.insurance.forminsurancepay.footer") }}
            </span>
          </b-col>
        </b-row>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.loadingDiv {
  display: none !important;
}

.text-1 {
  color: var(--black-900, #12070b);
  /* H1 */
  font-family: Jost;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-2 {
  color: var(--black-900, #12070b);
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-3 {
  color: var(--black-900, #12070b);

  /* H6 */
  font-family: Jost;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-4 {
  color: var(--black-700, #8a8a8a);

  display: block;
  /* SM */
  font-family: Jost;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-5 {
  color: var(--black-800, #4f4f4f);

  /* Body */
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-6 {
  color: var(--black-100, #fefefe);
  text-align: center;

  /* SM */
  font-family: Jost;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.radio-div {
  width: auto;
  height: 70px;
  flex-shrink: 0;

  border-radius: 10px;
  border: 1px solid var(--black-500, #dfdfdf);
  background: var(--black-100, #fefefe);

  //   border-radius: 10px;
  //   border: 3px solid var(--blue-600, #3c84d8);
  //   background: var(--black-100, #fefefe);
}

.radio-div-selected {
  width: auto;
  height: 55px;
  flex-shrink: 0;

  border-radius: 10px;
  border: 1px solid var(--black-500, #dfdfdf);
  background: var(--black-100, #fefefe);

  border-radius: 10px;
  border: 3px solid var(--blue-600, #3c84d8);
  background: var(--black-100, #fefefe);
}

.radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.radio-container input {
  margin-right: 10px;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #dfdfdf;
  border-radius: 50%;
}

.radio-container input:checked ~ .checkmark {
  background-color: #3c84d8;
}

.radio-container input:checked ~ .checkmark::after {
  display: block;
}

.divPackage {
  border-radius: 10px;
  border: 3px solid var(--blue-600, #3c84d8);
  background: var(--black-100, #fefefe);
}

.mt-5-custome {
  margin-top: 10rem !important;
}

.form-box-white {
  background: #fefefe;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 5%;
  //width: 700px;
}

.button-submit {
  display: flex;
  width: 300px;
  height: 41px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--blue-700, #2963b5);

  /* Body */
  font-family: Jost;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

$large: 992px;

.bg-content {
  @import "../../assets/styles/all.scss";

  background-image: url("../../assets/img/cyber-insurance-div1-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  border-top: 1px solid $color-border;

  @media screen and (min-width: $large) {
    .button-select {
      width: 190px;
      height: 41px;
    }

    .button-submit {
      width: 800px;
      height: 41px;
    }
  }
}

.bg-content-white {
  background-color: #1a4592;
  background-repeat: repeat;
  background-size: contain;
  background-position: top;

  @media screen and (min-width: $large) {
    .button-select {
      width: 190px;
      height: 41px;
    }

    // .custom-control-label {
    //   width: 650px !important;
    // }

    .button-submit {
      width: 800px;
      height: 41px;
    }

    .text-4 {
      display: inline;
    }

    // background-position: center;
    // background-size: cover;
    // position: relative;
  }
}

.div-padding {
  padding: 0% 5%;
}

.text-3 p {
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
  display: block !important;
}

.text-3 h1 {
  font-family: Jost;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  color: #4f4f4f;
  display: inline-block !important;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 50px;
  height: 50px;
  margin-top: -0.5em;

  border: 15px solid rgb(243, 55, 112);
  border-radius: 100%;
  border-bottom-color: transparent;
  -webkit-animation: spinner 1s linear 0s infinite;
  animation: spinner 1s linear 0s infinite;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";
import CyberInsuranceLoader from "./CyberInsuranceLoader.vue";
export default {
  components: {
    // Recaptcha,
    CyberInsuranceLoader,
  },
  data() {
    return {
      isValidData: false,
      receivedData: null,
      clientName: "",
      // email: "",
      // contact: "",
      // refKey: "",
      selectPackage: "11.43",
      divSelect: "radio-div",
      options: [
        // { value: "10", text: "RM 10 per month", desc: "Billed annually" },
        // { value: "14", text: "RM 14 per month", desc: "Billed monthly" },
        { value: "11.43", text: "RM 11.43 per month", desc: "Billed annually" },
      ],

      modalSuccessbox: false,
      modalFailbox: false,
      modalAgreebox: false,
      message: "",
      loading: true,
    };
  },
  computed: {
    isDisabled() {
      return !this.clientName; // Enable the button if this.name is not null or empty
    },
  },

  methods: {
    totalRoundUp(packageValue) {
      if (packageValue == 11.43) {
        let total = packageValue * 12;
        let roundedTotal = Math.ceil(total * 20) / 20;
        let totalRoundUp = roundedTotal - total;
        return totalRoundUp.toFixed(2);
      } else if (packageValue == 12) {
        return packageValue * 12;
      } else {
        return packageValue;
      }
    },
    annualPay(packageValue) {
      if (packageValue == 11.43) {
        let total = packageValue * 12;
        let roundedTotal = Math.ceil(total * 20) / 20;
        return roundedTotal.toFixed(2);
      } else if (packageValue == 12) {
        return packageValue * 12;
      } else {
        return packageValue;
      }
    },
    taxPay(packageValue) {
      if (packageValue == 11.43) {
        let total = packageValue * 12;
        let tax = (total * (6 / 100)).toFixed(2);
        return tax;
      } else if (packageValue == 12) {
        let total = packageValue * 12;
        let tax = (total * (6 / 100)).toFixed(2);
        return tax;
      } else {
        let total = packageValue;
        let tax = (total * (6 / 100)).toFixed(2);
        return tax;
      }
    },
    totalExTax(packageValue) {
      if (packageValue == 11.43) {
        let annual = packageValue * 12;
        let tax = (annual * (6 / 100)).toFixed(2);
        let toTax = parseFloat(annual) - parseFloat(tax);

        return toTax.toFixed(2);
      } else if (packageValue == 12) {
        let annual = packageValue * 12;
        let tax = (annual * (6 / 100)).toFixed(2);
        let toTax = parseFloat(annual) - parseFloat(tax);

        return toTax.toFixed(2);
      } else {
        let annual = packageValue;
        let tax = (annual * (6 / 100)).toFixed(2);
        let toTax = parseFloat(annual) - parseFloat(tax);

        return toTax.toFixed(2);
      }
      // let annual = packageValue * 12;
      // let tax = (annual * (6 / 100)).toFixed(2);
      // let toTax = parseFloat(annual) - parseFloat(tax);

      // return toTax.toFixed(2);
    },
    getSessionDataWithExpiry(key) {
      const itemStr = sessionStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        // Item has expired, remove it from sessionStorage
        sessionStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    getDecryptedData(data) {
      const encryptionKey = "your-secret-key";
      if (data) {
        const decryptedData = CryptoJS.AES.decrypt(data, encryptionKey);
        const decryptedDataString = decryptedData.toString(CryptoJS.enc.Utf8);
        const jsonDataReceived = JSON.parse(decryptedDataString);
        return jsonDataReceived;
      } else {
        return null;
      }
    },
    openNewTab(page) {
      const newTabPageUrl = page; // Replace with the path to your new tab page
      // window.open(newTabPageUrl, "_blank"); // Open new tab
      // this.$router.push({ name: "cyberinsurance" });
      window.location.replace(newTabPageUrl);
    },
    submit(response) {
      return response;
    },
    onSubmit() {
      this.loading = false;
      // setTimeout(() => {
      //   this.loading = true;
      // }, 2000);

      if (this.selectPackage != "") {
        this.message = "";

        const formData = new FormData();
        formData.append("client", this.clientName);
        formData.append("email", this.receivedData.email);
        formData.append("contact", this.receivedData.contact);
        formData.append("refKey", this.receivedData.refKey);
        formData.append("package", this.selectPackage);
        formData.append("item", this.annualPay(this.selectPackage));
        formData.append("exclude", this.totalExTax(this.selectPackage));
        formData.append("tax", this.taxPay(this.selectPackage));
        formData.append("total", this.annualPay(this.selectPackage));

        axios
          .post(
            "https://insuranceapi.tekkis.my/Webv2/microsite/payloadData",
            formData,
            {
              headers: {
                Accept: "application/json",
              },
            }
          )
          .then((response) => {
            //const dataPayload = JSON.parse(response.data);
            //console.log(response.data.paymentInfo);
            if (response.data.status == "pending") {
              const dataPayment = JSON.parse(response.data.paymentInfo);
              const dataDetail = dataPayment.response.paymentDetails;
              this.openNewTab(dataDetail.payment_link);
            } else {
              this.modalFailbox = true;
            }
            document.getElementById("submitbtn").disabled = false;
          });
      } else {
        this.modalAgreebox = true;
      }
    },
    checkSessionData() {
      const encryptedData = this.getSessionDataWithExpiry("infoUser");
      // console.log(typeof encryptedData);
      if (encryptedData !== null) {
        this.isValidData = true;
        const decryptedData = this.getDecryptedData(encryptedData);
        this.receivedData = decryptedData;
        this.clientName = decryptedData.client;
      } else {
        setTimeout(function () {
          location.href = "/cyberinsurance";
        }, 1000);
      }
    },
  },
  mounted() {
    this.checkSessionData();
  },
};
</script>
